// --------- start : ant-pinker -----------

.ant-picker {
    padding: 8px 11px 8px 16px !important;
    border: 0 !important;
    border-radius: 8px !important;
    background: var(--background-card) !important;
    // box-shadow: 0 12px 24px 0 var(--box-shadow) !important;
}

.ant-picker-input>input {
    font-size: 16px !important;
    color: var(--text-primary) !important;
}

.ant-picker-range .anticon {
    svg {
        fill: var(--icon)
    }
}

.ant-picker-range .ant-picker-active-bar {
    background: $primary-color !important;
}

.ant-picker:hover,
.ant-picker-focused {
    border-color: $primary-color !important;
}

.ant-picker-focused,
.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
    border-color: $primary-color !important;
    box-shadow: 0 0 0 2px rgba($primary-color, 0.2) !important;
}

.ant-picker-separator {
    height: auto !important;
}

.ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner {
    background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single)::before,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single)::before {
    background: $primary-color !important;
}

.ant-picker-cell-in-view.ant-picker-cell-in-range::before {
    background: rgba($primary-color, 0.1) !important;
}

.ant-picker-cell-in-view.ant-picker-cell-range-start:not(.ant-picker-cell-range-start-single):not(.ant-picker-cell-range-end) .ant-picker-cell-inner,
.ant-picker-cell-in-view.ant-picker-cell-range-end:not(.ant-picker-cell-range-end-single):not(.ant-picker-cell-range-start) .ant-picker-cell-inner {
    width: 100%;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-end .ant-picker-cell-inner::after {
    right: -6px !important;
    background: rgba($primary-color, 0.2) !important;
}

.ant-picker-date-panel .ant-picker-cell-in-view.ant-picker-cell-in-range.ant-picker-cell-range-hover-start .ant-picker-cell-inner::after {
    left: -6px !important;
    background: rgba($primary-color, 0.2) !important;
}

.ant-picker-cell:hover:not(.ant-picker-cell-in-view) .ant-picker-cell-inner,
.ant-picker-cell:hover:not(.ant-picker-cell-selected):not(.ant-picker-cell-range-start):not(.ant-picker-cell-range-end):not(.ant-picker-cell-range-hover-start):not(.ant-picker-cell-range-hover-end) .ant-picker-cell-inner {
    background: rgba($primary-color, 0.2) !important;
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-color: $primary-color !important;
}

.ant-switch-inner {
    display: flex !important;
}

.ant-input-wrapper {
    .ant-input-group-addon {
        border-color: var(--background-card);
        border-radius: 8px;
        background: var(--background-card);
    }

    .ant-input-affix-wrapper,
    .ant-input {
        border-color: var(--background-card);
        border-radius: 8px;
        background: var(--background-card);
        color: var(--text-primary);
    }

    svg {
        fill: var(--icon);
    }
}

.ant-picker-clear {
    background: var(--background-card) !important;
}

.ant-empty-description {
    color: var(--text-secondary) !important;
}

.ant-table {
    background: var(--background-card) !important;
    color: var(--text-primary) !important;
    will-change: background;

    .ant-table-cell {
        white-space: nowrap !important;
    }

    .ant-table-cell-fix-left,
    .ant-table-cell-fix-right {
        background: var(--background-card);
        will-change: background;
    }

    .ant-table-tbody>tr.ant-table-row:hover>td {
        background: var(--background-card-hover) !important;
    }

    .ant-table-thead>tr:nth-child(2) {
        th {
            border-top: 1px solid var(--line);
        }
    }

    .ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        bottom: 0 !important;
        height: 100% !important;
    }

    .ant-table-tbody>tr>td {
        border-color: var(--line) !important;
    }

    &.ant-table-bordered>.ant-table-container {
        border-left: 0 !important;
    }

    &.ant-table-bordered>.ant-table-container>.ant-table-content>table,
    &.ant-table-bordered>.ant-table-container>.ant-table-header>table {
        border-top: 0 !important;
    }

    .ant-table-summary,
    .ant-table-summary .ant-table-cell-fix-left {
        background: var(--background-card-hover) !important;
    }

    .ant-table-summary>tr>th,
    .ant-table-summary>tr>td {
        border-bottom: 0 !important;
    }

    .ant-table-placeholder {
        color: var(--text-secondary) !important;
        font-size: 16px;
    }

    .ant-table-tbody>tr.ant-table-placeholder:hover>td {
        background: var(--background-card) !important;
    }
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>thead>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>th,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tfoot>tr>td,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tfoot>tr>td {
    border-right-color: var(--line) !important;
}

.ant-table.ant-table-bordered>.ant-table-container>.ant-table-content>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-header>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-body>table>tbody>tr>td>.ant-table-expanded-row-fixed::after,
.ant-table.ant-table-bordered>.ant-table-container>.ant-table-summary>table>tbody>tr>td>.ant-table-expanded-row-fixed::after {
    border: 0 !important;
}

.ant-radio-button-wrapper {
    color: var(--text-primary) !important;
    background: var(--bg-radio) !important;
    border: 1px solid var(--line) !important;

    &:hover {
        color: $secondary-color !important;
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    color: $white-color !important;
    background: $secondary-color !important;
    border-color: $secondary-color !important;

    &.radio-close {
        background: $red-color !important;
        border-color: $red-color !important;
    }

    &.radio-view {
        background: $secondary-color !important;
        border-color: $secondary-color !important;
    }

    &.radio-edit {
        background: $orange-color !important;
        border-color: $orange-color !important;
    }
}

.ant-radio-button-wrapper:first-child {
    border-radius: 8px 0 0 8px !important;
}

.ant-radio-button-wrapper:last-child {
    border-radius: 0 8px 8px 0 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: var(--bg-radio) !important;
    padding: 0 !important;
}

.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    &::before {
        background-color: $primary-color !important;
    }

    &.radio-close {
        &::before {
            background: $red-color !important;
        }
    }

    &.radio-view {
        &::before {
            background: $secondary-color !important;
        }
    }

    &.radio-edit {
        &::before {
            background: $orange-color !important;
        }
    }
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: 0 0 0 3px rgba($secondary-color, 0.08) !important;
}

.ant-input-group-wrapper {
    box-shadow: 0 12px 24px 0 var(--box-shadow);
    border-radius: 8px;
    overflow: hidden;
}

.ant-input-group-wrapper .ant-input-affix-wrapper {
    border-top-left-radius: 0 !important;
    border-bottom-left-radius: 0 !important;
    border-color: transparent !important;

    &:focus,
    &:hover {
        border-color: $primary-color !important;
    }
}

.ant-select-selector {
    background-color: var(--background-card) !important;
    border-radius: 8px !important;
    border-color: var(--input-border) !important;
    color: var(--text-primary) !important;
    height: 40px !important;

    .ant-select-selection-item {
        display: flex !important;
        align-items: center !important;
    }
}

.ant-select-dropdown {
    background: var(--background) !important;
    border-radius: 8px !important;
    padding: 0 !important;

    .ant-select-item {
        color: var(--text-primary) !important;
    }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
        box-shadow: 0 0 0 2px rgba($primary, 0.2) !important;
        border-color: $primary !important;
    }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: rgba($primary-color, 0.1) !important;
}

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: rgba($primary-color, 0.1) !important;
}

.ant-select-arrow .ant-select-suffix svg {
    fill: var(--text-secondary);
}

// --------- end : ant-pinker -----------

.datepicker-floating {
    label {
        transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
    }

    .ant-picker {
        width: 100%;
        padding: 0 !important;
        border: 1px solid #E6E8F1 !important;
        border-radius: 8px;
    }

    .ant-picker-input input {
        padding: 1.625rem 0 0.625rem 0.75rem;
    }

    .ant-picker-suffix {
        font-size: 20px;
        padding-right: 11px;
    }
}

// -------- start : ant-table ----------
.ant-box-table {
    padding: 0;
}

.ant-table-thead>tr>th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
    width: 0 !important;
}

.ant-table .ant-table-thead>tr>th {
    background: $primary-color !important;
    color: $white-color !important;
    border-bottom: 0 !important;
}

.ant-table-pagination.ant-pagination {
    margin: 16px 16px 16px 0 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link,
.ant-pagination-item {
    background-color: var(--background-card) !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-color: var(--input-border) !important;
    color: var(--text-primary) !important;
    border-radius: 8px !important;

    &:hover {
        color: $primary-color !important;
        border-color: $primary-color !important;
    }
}

.ant-pagination-item-active {
    color: $primary-color !important;
    border-color: $primary-color !important;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
    opacity: 0.25;
}

@media (max-width: 768px) {
    .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next {
        min-width: 36px !important;
        width: 36px !important;
        height: 36px !important;
    }    
}

// --------- end : ant-table -----------


// -------- start : ant-switch ----------
.ant-switch-checked {
    background-color: $green-color !important;

    &:focus {
        box-shadow: 0 0 0 2px $green-color !important;
    }
}

// --------- end : ant-switch -----------



.ant-dropdown-menu.profile-link-hover {
    padding: 10px 5px;
    position: absolute !important;
    top: calc(100% + 8px) !important;
    right: 0 !important;
    min-width: 200px !important;
    background: var(--background-card) !important;
    box-shadow: 0 12px 24px 0 rgb(128 144 208 / 8%) !important;
    border-radius: 16px !important;
    border: 1px solid rgba(142, 142, 142, 0.1) !important;
    z-index: 99 !important;

    a,
    div {
        display: block;
        padding: 4px 0;
        color: var(--text-primary);
        text-shadow: 0 0 0 rgba($black-color, 0);
        transition: all 0.25s ease;

        &:hover {
            color: $primary-color;
            text-shadow: 0 2px 4px rgba($black-color, .1);
        }
    }


    .ant-dropdown-menu-item:hover,
    .ant-dropdown-menu-submenu-title:hover {
        background: var(--background-card) !important;
    }

    // .ant-dropdown-menu-submenu{
    //     padding-left: 12px !important;
    // }
}

.ant-spin-text {
    color: var(--text-secondary) !important;
    margin-top: 16px;
}

.ant-card-actions>li>span a:not(.ant-btn):hover,
.ant-card-actions>li>span>.anticon:hover {
    color: $primary !important;
}

.ant-input-affix-wrapper,
.ant-input-number-affix-wrapper {
    width: 100% !important;
    // background-color: var(--background-card) !important;
    border-radius: 8px !important;
    border-color: var(--input-border) !important;
    height: 40px;

    &.ant-input-affix-wrapper-disabled {
        background-color: var(--background) !important;
        opacity: 0.5;
    }

    &:focus, &.ant-input-number-affix-wrapper-focused {
        border-color: $primary !important;
        box-shadow: 0 0 0 2px rgba($primary, 0.2) !important;
    }
}

.ant-input {
    border-color: var(--input-border) !important;

    &:focus {
        border-color: $primary !important;
        box-shadow: 0 0 0 2px rgba($primary, 0.2);
    }
}

.ant-input-number-group-wrapper {
    width: 100% !important;

    .ant-input-number-affix-wrapper {
        border-radius:  8px 0 0 8px !important;
    }

    .ant-input-number-group-addon {
        border-radius: 0 8px 8px 0 !important;
        border-color: var(--input-border);
        background-color: var(--background);
        color: var(--text-primary) !important;
    }
}

.ant-form-item-label label {
    color: var(--text-primary) !important;
    height: auto !important;
    line-height: 1 !important;
}

.ant-input,
.ant-input-number {
    width: 100% !important;
    color: var(--text-primary) !important;
    background-color: transparent !important;

    &::placeholder {
        color: var(--text-third) !important;
    }
}

.ant-input-suffix {
    color: var(--text-secondary);
}

.ant-select-single:not(.ant-select-customize-input) {
    .ant-select-selector {
        .ant-select-selection-search-input {
            height: 40px !important;
        }
    }
}

.ant-checkbox-wrapper {
    color: var(--text-primary) !important;
    font-size: 16px !important;

    .ant-checkbox-inner {
        width: 20px !important;
        height: 20px !important;
        background-color: var(--background-card) !important;
        border-color: var(--input-border) !important;
        border-radius: 5px !important;
    }

    .ant-checkbox-checked {
        .ant-checkbox-inner {
            background-color: $primary !important;
            border-color: $primary !important;
            z-index: 2;

            &::after {
                border-color: $black-color !important;
            }
        }

        &::after {
            border-radius: 5px !important;
        }
    }
}

.ant-input-number {
    display: flex !important;
    align-items: center;
}

.ant-input-number-handler-wrap {
    background: var(--background) !important;
    border-radius: 4px !important;
}

.ant-input-number-handler {
    border-left: 0 !important;

    svg {
        fill: var(--text-primary);
    }

    &.ant-input-number-handler-down {
        border-top-color: var(--input-border) !important;
    }

    &.ant-input-number-handler-up,
    &.ant-input-number-handler-down {
        &:hover {
            svg {
                fill: $primary !important;
            }
        }
    }
}

.ant-input-number-feedback-icon {
    display: flex;
}

.ant-drawer-content,
.ant-drawer-header {
    background-color: var(--background-card) !important;
    border-bottom-color: var(--input-border) !important;
}

.ant-drawer-title {
    color: var(--text-primary) !important;
}

.anticon-close svg {
    fill: var(--text-primary);
}

.upload-image {
    display: flex;
    align-items: center;

    .ant-upload-picture-card-wrapper {
        width: auto;
    }

    .ant-upload-select-picture-card {
      width: 240px !important;
      height: 240px !important;
    }
}

.setting-bank {
    .ant-btn-link:focus, .ant-btn-link:hover {
        color: #000 !important;
    }
}

.ant-table .ant-table-cell .row-remark {
    min-width: 20px;
    max-height: 280px;
	padding: 2px 12px;
	font-size: 12px;
	white-space: normal!important;
	border-radius: 10px;
	display: block;
}

.antd-custom-panel {
    .ant-collapse-header, .ant-collapse-content-box {
        padding-left: 0 !important;
        padding-right: 0 !important;
    }
}

.disabled-row {
    background-color:var(--background);
    pointer-events: none;
}

.ant-picker-panels {
    flex-direction: column !important;

    @media (min-width: 576px) {
        flex-direction: row !important;
    }
}

.ant-breadcrumb {
    color: var(--text-secondary) !important;

    .ant-breadcrumb-separator {
        color: rgba(var(--text-secondary), 0.5) !important;
    }

    a {
        color: var(--text-secondary) !important;

        &:hover {
            color: var(--text-primary) !important;
        }
    }
}

.ant-input-disabled, .ant-input[disabled] {
    color: var(--text-third) !important;
    background-color: var(--input-bg-disable) !important;
}

.ant-input-affix-wrapper .ant-input {
    border-radius: 0 !important;
}

/* DEEP Custom */
table.table tbody tr:not(.footer-table) td {
    white-space: nowrap;
}

.ant-table-cell span.text-span {
    white-space: nowrap;
    display: flex;
}

.ant-table-cell {
    font-size: .8125rem;
    line-height: .9375rem;
    font-weight: 400;
}

.ant-cus-vertical{
    flex-direction: column;
}

.table-padding-6{
    padding: 6px;
}

.ant-table-container thead tr th{
    white-space: pre-line;
    // border: 1px solid #bbb;
    vertical-align: middle;
    padding: 0.5rem;
}

.ant-img-circle {
    border-radius: 50%;
}