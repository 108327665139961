:root {
    --background: #{$grey-color-1};
    --background-card: #{$white-color};
    --background-card-hover: #{lighten($primary-color, 48%)};
    --heading: #{$light-grey-700};
    --text-primary: #{lighten($light-grey-700, 10%)};
    --text-primary-hover: #{$light-grey-700};
    --text-secondary: #{$light-grey-500};
    --text-secondary-hover: #{$light-grey-700};
    --text-third: #{$light-grey-400};
    --text-third-hover: #{$light-grey-500};
    --text-active: #{$black-color};
    --text-shadow: rgba(255,255,255, .3);
    --icon: #{rgba(0, 0, 0, 0.25)};
    --icon-disabled: #{rgba(0, 0, 0, 0.1)};
    --line: #{rgba(0, 0, 0, 0.1)};
    --input-on-background: #{$white-color};
    --input-border: #d9d9d9;
    --input-bg-disable: #{$light-grey-100};
    --bg-light: #f8f9fa;
    --bg-radio: #{$white-color};
    --bg-title-bar: rgba(255, 255, 255, 0.9);
    --box-shadow: rgb(128, 144, 208, 0.15);
}

[data-is-dark='true'] {
    --background: #{$black-color};
    --background-card: #{$dark-grey-500};
    --background-card-hover: #{darken($dark-grey-500, 2.5%)};
    --heading: #{$dark-grey-100};
    --text-primary: #{darken($dark-grey-100, 10%)};
    --text-primary-hover: #{$dark-grey-100};
    --text-secondary: #{$dark-grey-400};
    --text-secondary-hover: #{$dark-grey-100};
    --text-third: #{$dark-grey-400};
    --text-third-hover: #{$dark-grey-500};
    --text-active: #{$white-color};
    --text-shadow: rgba(0, 0, 0, 0);
    --icon: #{rgba(255, 255, 255, 0.3)};
    --icon-disabled: #{rgba(255, 255, 255, 0.1)};
    --line: #{rgba(255, 255, 255, 0.1)};
    --input-on-background: #{$black-color};
    --input-border: #3d3d3d;
    --input-bg-disable: #{$light-grey-700};
    --bg-light: #1c1c1c;
    --bg-radio: #1c1c1c;
    --bg-title-bar: rgba(0, 0, 0, 0.9);
    --box-shadow: rgb(0, 0, 0, 0.5);
}


[data-is-fullscreen='false'] {
    --max-width: 1450px;
}

[data-is-fullscreen='true'] {
    --max-width: 100%;
}