.auth-page{
    background: url('../img/auth-bg.jpg') center;
    background-size: cover;
    position: relative;
    overflow: hidden;
    &:after{
        content: '';
        position: absolute;
        top: 4%;
        left: 48%;
        width: 50vw;
        height: 1200px;
        background: url('../img/auth-obj.svg') top center no-repeat;
        background-size: 100% cover;
    }
    .logo{
        position: absolute;
        top: 30px;
        right: 40px;
        max-width: 230px;
    }
}

.auth-area{
    height: 100vh;
	display: flex;
	align-items: center;
	justify-content: flex-start;
}

.ant-dropdown-menu{
    padding: 6px 0 !important;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15) !important;
}
.ant-dropdown-menu-item, 
.ant-dropdown-menu-submenu-title{
    padding: 6px 20px 6px 12px !important;
}
.ant-dropdown-menu-title-content{
    font-size: 16px;
    img{
        display: inline-block;
        vertical-align: top;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        object-fit: cover;
        position: relative;
        top: -1px;
        margin-right: 8px;
    }
}

.auth-wrapper{
    position: relative;
    z-index: 10;
    width: 600px;
    padding: 80px 60px 100px 60px;
    margin-left: 10vw;
    border-radius: 40px;
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.15);
    .ant-dropdown-link{
        position: absolute;
        top: 25px;
        right: 35px;
        img{
            display: inline-block;
            vertical-align: top;
            width: 24px;
            height: 24px;
            border-radius: 50%;
            object-fit: cover;
            position: relative;
            top: -1px;
            margin-right: 8px;
        }
        i{
            margin-left: 8px;
        }
    }
    .h-title{
        text-align: center;
        font-weight: 600;
        font-size: 54px;
        margin-bottom: 40px;
        color:#000;
    }
    .form-group{
        position: relative;
        margin-bottom: 30px;
        i{
            font-size: 24px;
            position: absolute;
            z-index: 10;
            left: 20px;
            top: 18px;
            color: #838A8E;
        }
    }
    .form-floating{
        border: none;
        height: 60px;
        border-radius: 30px;
        border: 1px solid #F3F3F3;
        background: rgba(255, 255, 255, 0.75);
        backdrop-filter: blur(44px);
        box-shadow: 2px 2px 6px rgba(0, 0, 0, 0.15);
        & > label{
            padding-left: 55px;
        }
        & > .form-control{
            padding-left: 55px;
        }
        & > .form-control:autofill{
            padding-top: 30px;
        }
    }
    .form-floating > .form-control:focus ~ label, 
    .form-floating > .form-control:not(:placeholder-shown) ~ label, 
    .form-floating > .form-select ~ label{
        padding-left: 61px;
    }
    .ant-btn-primary{
        font-size: 18px;
        letter-spacing: 1px;
        border: none;
        border-radius: 30px;
        height: 60px;
        box-shadow: 2px 4px 8px rgba(0, 0, 0, 0.25);
        background: linear-gradient(263.41deg, #5EECFA -7.67%, #3154C7 97.85%);
        background-size: 100%;
        transition: all 800ms ease;
        
        &:hover{
            background: linear-gradient(263.41deg, #5EECFA -7.67%, #3154C7 97.85%);
            background-size: 150%;
        }
    }
}
@supports (backdrop-filter: none) {
    .auth-wrapper{
        background: rgba(238,238,238,0.4);
        backdrop-filter: blur(55px);
        .lang{
            .link{
                color: var(--color-light);
            }
        }
    }
}
@supports not (backdrop-filter: none) {
    .auth-wrapper{
        background-color: rgba(255,255,255,0.9);
        .lang{
            .link{
                color: var(--color-default);
            }
        }
    }
}


// Responsive
@media (max-width: 1440px) {
    .auth-wrapper{width: 500px; padding: 70px 60px 80px 60px;}
    .auth-wrapper .h-title{font-size: 46px; margin-bottom: 30px;}
}

@media (max-width: 1199px) {
    .auth-page:after{left: auto; right: 2%; width: 46vw; height: auto; bottom: 0; background-position: bottom right;}
    .auth-page .logo{width: 200px;}
    .auth-wrapper{margin-left: 6%; width: 440px; padding: 60px 40px 70px 40px; margin-top: 60px; margin-bottom: 60px;}
    .auth-wrapper .h-title{font-size: 40px; margin-bottom: 25px;}
}

@media (max-width: 992px) {
    .auth-page:after{display: none;}
    .auth-page .logo{width: 160px; top: 20px; right: 30px;}
    .auth-area{justify-content: center; height: auto; min-height: 100vh;}
    .auth-wrapper{margin: 100px 30px 40px 30px;}
}

@media (max-width: 768px) {
    .auth-wrapper{padding: 50px 30px 50px 30px;}
    .auth-wrapper .h-title{font-size: 34px; margin-bottom: 20px;}
    .auth-wrapper .ant-dropdown-link{top: 20px; right: 25px;}
    .auth-wrapper .form-group{margin-bottom: 20px;}
}