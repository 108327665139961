.msn-badge {
    min-width: 20px;
	height: 20px;
	padding: 0 12px;
	font-size: 12px;
	white-space: nowrap;
	text-align: center;
	border-radius: 10px;
	overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
	display: inline-block;
}

.msn-badge-wrap {
    min-width: 20px;
    max-height: 280px;
	padding: 2px 12px;
	font-size: 12px;
	white-space: normal;
	border-radius: 10px;
	display: block;
}

.badge-large {
    font-size: 14px;
    font-weight: normal;
    border-radius: 8px;
}

.badge-info {
    @include msn-badge-color($primary-color);
}

.badge-danger {
    @include msn-badge-color($danger);
}

.badge-warning {
    @include msn-badge-color($warning);
}

.badge-success {
    @include msn-badge-color($success);
}

.badge-light {
    background: var(--background);
    color: var(--text-primary);
}