@import "variables";
@import "theme";

.sidebar-container {
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100% !important;
    max-width: 100% !important;
    background: var(--bg-title-bar) !important;
    backdrop-filter: blur(6px);
    z-index: 999;
    display: none;

    &.open-menu {
        display: block;
    }

    @media (min-width: 992px) {
        position: relative !important;
        width: auto !important;
        background: transparent !important;
        backdrop-filter: none;
        z-index: 1;
        display: block;
    }
}

.sidebar {
    width: auto;
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    padding: 72px 12px 24px;
    height: 100%;

    @media (min-width: 992px) {
        padding: 32px 0 32px 12px;
        height: auto;
        position: sticky;
        top: 0;
    }

    .nav-pills {
        // margin: 0 -15px;

        .nav-link {
            display: flex;
            align-items: center;
            width: 100%;
            padding: 12px 0;
            border-radius: 5px;
            color: var(--text-primary);

            i {
                width: 32px;
                font-size: 20px;
                margin-right: 8px;
                transition: all 0.25s ease;
            }

            .fa-chevron-down {
                width: auto;
                font-size: 14px;
                display: flex;
                margin-left: auto;
                transform-origin: center center;
            }

            &:not(.active) {
                i {
                    color: var(--text-primary);
                }

                &:hover {
                    color: var(--text-primary-hover);

                    i:not(.fa-chevron-down) {
                        color: $primary-color;
                    }
                }
            }

            &[aria-expanded="true"] {
                .fa-chevron-down {
                    transform: rotate(180deg);
                }
            }
        }
    }

    .nav-pills .nav-link.active,
    .nav-pills .show > .nav-link {
        color: var(--text-active);
        font-weight: bold;
        background-color: transparent;

        i {
            color: $primary-color;
        }
    }
}

.sidebar-footer {
    position: relative;
    font-size: 16px;
    color: var(--text-secondary);
    margin-bottom: 24px;

    &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 1px;
        background-color: rgba(142, 142, 142, 0.2);
    }

    .nav-link {
        padding: 24px 12px 0;
        padding-top: 24px !important;
    }
}

.nav-sub-item {
    padding-left: 56px;

    .nav-sub-link {
        display: block;
        margin: 6px 0;
        color: var(--text-secondary);

        &.active {
            color: var(--text-active);
            font-weight: bold;
        }

        &:hover {
            color: var(--text-active);
        }
    }
}

.sidebar-menu {
    overflow-y: scroll;
    overflow-x: hidden;
    padding-bottom: 16px;

    // &::-webkit-scrollbar {
    //   width: 1em;
    // }
    // &::-webkit-scrollbar-thumb {
    //   border: 4px solid transparent;
    //   background: $primary-color;
    //   border-radius: 100px;
    //   background-clip: content-box;
    // }
}

.sidebar-logo {
    width: 150px;
    display: block;
    position: relative;
}
