@font-face {
    font-family: 'Slip Li';
    font-weight: 400;
    font-style: normal;
    src: local('Slip Li'),
        url('../fonts/fonts-slip/DBHeaventt-Light.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fonts-slip/DBHeaventt-Light.ttf') format('truetype'),
        url('../fonts/fonts-slip/DBHeaventt-Light.woff2') format('woff2'),
        url('../fonts/fonts-slip/DBHeaventt-Light.woff') format('woff'),
        url('../fonts/fonts-slip/DBHeaventt-Light.svg#DBHeaventt-Light') format('svg');
}

@font-face {
    font-family: 'Slip Reg';
    font-weight: 400;
    font-style: normal;
    src: local('Slip Reg'),
        url('../fonts/fonts-slip/DBHeavent.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fonts-slip/DBHeavent.ttf') format('truetype'),
        url('../fonts/fonts-slip/DBHeavent.woff2') format('woff2'),
        url('../fonts/fonts-slip/DBHeavent.woff') format('woff'),
        url('../fonts/fonts-slip/DBHeavent.svg#DBHeavent') format('svg');
}

@font-face {
    font-family: 'Slip Med';
    font-weight: 400;
    font-style: normal;
    src: local('Slip Med'),
        url('../fonts/fonts-slip/DBHeavent-Med.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fonts-slip/DBHeavent-Med.ttf') format('truetype'),
        url('../fonts/fonts-slip/DBHeavent-Med.woff2') format('woff2'),
        url('../fonts/fonts-slip/DBHeavent-Med.woff') format('woff'),
        url('../fonts/fonts-slip/DBHeavent-Med.svg#DBHeavent-Med') format('svg');
}

@font-face {
    font-family: 'SlipSerif';
    font-weight: 400;
    font-style: normal;
    src: local('SlipSerif'),
        url('../fonts/fonts-slip/SCBPSLxTextErgo.eot?#iefix') format('embedded-opentype'),
        url('../fonts/fonts-slip/SCBPSLxTextErgo.ttf') format('truetype'),
        url('../fonts/fonts-slip/SCBPSLxTextErgo.woff2') format('woff2'),
        url('../fonts/fonts-slip/SCBPSLxTextErgo.woff') format('woff'),
        url('../fonts/fonts-slip/SCBPSLxTextErgo.svg#SCBPSLxTextErgo') format('svg');
}