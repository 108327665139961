.text-muted {
    color: var(--text-third) !important;
}

.btn {
    border-radius: 8px !important;
}

.btn-primary {
    @include btn-box-shadow($primary, true);

    &:hover {
        background-color: $primary;
        border-color: $primary;
        box-shadow: 0 8px 16px 0 rgba(249,193,0,0.16);
    }
}

.btn-light {
    color: var(--heading);
    background-color: var(--bg-light);
    border-color: var(--bg-light);
}

// .btn-info {
//     @include btn-box-shadow($info, true);
// }

// .btn-success {
//     @include btn-box-shadow($success, true);
// }

// .btn-danger {
//     @include btn-box-shadow($danger, true);
// }

// .btn-warning {
//     @include btn-box-shadow($warning, true);
// }

.form-floating {
    border: 1px solid var(--input-border);
    border-radius: 8px;
    box-shadow: 0 8px 16px rgba(128,144,208,0.1);
    overflow: hidden;
}

.form-floating > .form-control, .form-floating > .form-select {
    color: var(--text-primary);
    background-color: var(--background-card);
    border: 0px solid transparent;
    border-radius: 0;

    ~ label {
        color: var(--text-primary);
        opacity: 0.65 !important;
    }
}

.form-fixed-data {
    display: flex;
    flex-direction: row-reverse;
    background-color: var(--background-card);

    span {
        align-self: flex-end;
        padding: 0 0 8px 0.75rem;
        color: var(--text-third);
        display: none;
    }

    .form-control {
        background-color: transparent !important;
        box-shadow: 0 0 0;
        padding-left: 0;
    }

    > .form-control:focus ~ span,
    > .form-control:not(:placeholder-shown) ~ span,
    > .form-select ~ span {
        display: block;
    }
}

.modal-content {
    .modal-close {
        i {
            color: var(--text-secondary);
            transition: all 0.25s ease;
        }

        &:hover {
            i {
                color: var(--text-secondary-hover);
            }
        }
    }

    .form-floating > .form-control,
    .form-floating > .form-select,
    .form-fixed-data {
        background-color: var(--input-on-background);
    }
}

.navbar {
    padding-top: 1rem;
    padding-bottom: 1rem;
    box-shadow: 0 12px 24px 0 var(--box-shadow);

    @media (min-width: 1024px) {
        padding-top: 0.5rem;
        padding-bottom: 0.6rem;
    }
}

.nav-tabs {
    border: 0;

    .nav-item {
        padding: 0 4px;
    }

    .nav-link {
        padding: 8px 1rem;
        color: var(--text-primary);
        border-radius: 8px;
        box-shadow: 0 6px 12px 0 rgba($primary-color, 0);
        cursor: pointer;

        &.active {
            color: $black-color;
            box-shadow: 0 6px 12px 0 rgba($primary-color, 0.2);
        }

        &:not(.active) {
            &:hover {
                color: $black-color;
                background-color: $primary-color;
                border-color: $primary-color;
                box-shadow: 0 6px 12px 0 rgba($primary-color, 0.2);
            }
        }
    }
}

.nav-text {
    border-bottom: 1px solid rgba($grey-color-3, .2);

    .nav-link {
        padding: 8px 1rem;
        color: var(--text-primary);
        border-bottom: 2px solid transparent;
        cursor: pointer;

        &.active {
            color: var(--text-active);
            font-weight: bold;
            border-bottom: 2px solid $primary-color;
        }

        &:not(.active) {
            &:hover {
                color: var(--text-primary-hover);
                border-bottom: 2px solid $primary-color;
            }
        }
    }
}

.spinner-loading{
    color: $secondary-color;
}


.text-link{
    cursor: pointer;
    color: $blue-color;
    .ant-typography {
        color: $blue-color;
    }
}